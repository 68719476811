import React, { useState } from "react";
import { Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import imageurl from "services/images";

const SecondPageBanner = () => {
  const [email, setEmail] = useState("");
  const [consent, setConsent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleScroll = () => {
    const element = document.getElementById("target-section");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !consent) {
      alert("Please fill out the email and agree to updates.");
      return;
    }
    setLoading(true);

    try {
      const response = await fetch(
        "https://assets.mailerlite.com/jsonp/1159803/forms/139188237500418000/subscribe",
        {
          method: "POST",
          body: new URLSearchParams({ "fields[email]": email }),
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      );

      if (response.ok) {
        setSuccess(true);
      } else {
        alert("Something went wrong. Please try again later.");
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ paddingY: 1, background: "#fff" }}>
        <Grid container>
          <Grid item xs={12} lg={6} >
            <img src={imageurl.banner1} alt="Login-logo" className="w-100" />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                overflow: "hidden",
                alignItems: "center"

              }}
            >
              <img
                src={imageurl.banner2}
                alt="First Banner"
                className="w-100"
              />

              <Box
                sx={{
                  position: "absolute",
                  color: "#fff",
                  zIndex: 10,
                  textAlign: "center",
                  mx: 2,
                  mt: 4,

                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        lineHeight: 1.1,
                        mx: { xs: 0, md: 3 },

                        fontSize: { xl: "42px", lg: "32px", md: "44px", sm: "36px", xs: "16px" },
                      }}
                      variant="h2"
                    >
                      Find the perfect gift every time, with Gimmie!
                    </Typography>

                    <Typography
                      className="mt-2"
                      variant="body1"
                      sx={{
                        color: "#D3D3D3",
                        mx: { xs: 0, md: 3 },
                        fontSize: { xl: "18px", lg: "14px", md: "20px", sm: "16px", xs: "10px" },
                      }}
                    >
                      Personalized AI-powered gift recommendations for any and every
                      occasion! Tell us who you're shopping for
                    </Typography>

                    <Button
                      onClick={handleScroll}
                      className="explore-btn"
                      disableRipple
                      size="small"
                      sx={{
                        background: "transparent",
                        color: "#fff",
                        border: "1px solid #fff",
                        p: "10px 40px",
                        borderRadius: 2,
                        fontWeight: 600,
                        mx: { xs: 0, md: 3 },
                        mt: 2
                      }}
                    >
                      Explore More
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider
                      sx={{
                        borderColor: '#fff',
                        width: '100%',
                        margin: '16px 0',
                        mt: 4,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} >
                    <Box
                      component="form" onSubmit={handleSubmit}
                      sx={{
                        width: "100%",
                        maxWidth: "600px",
                        padding: { md: "12px", },
                        color: "#fff",
                        borderRadius: "8px",
                        position: "relative",
                        boxSizing: "border-box",
                        m: "auto",
                        mt: { xs: 2, md: 0 }
                      }}
                    >
                      <Typography
                        variant="h2"
                        sx={{
                          fontSize: { xl: "38px", lg: "32px", md: "44px", sm: "36px", xs: "16px" },
                        }}
                      >
                        Exclusive Early Access
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#D3D3D3",
                          fontSize: { xl: "16px", lg: "14px", md: "20px", sm: "16px", xs: "10px" },
                        }}
                      >
                        Register your email to receive a personal invite to Gimmie before the public release!
                      </Typography>
                      <TextField

                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        variant="outlined"
                        InputProps={{
                          style: {
                            borderRadius: "40px",
                            backgroundColor: "#fff",
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            fontSize: "14px",
                          },
                        }}
                        sx={{
                          width: "70%",
                          mt: 2,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "40px",
                            "& fieldset": {
                              borderColor: "#ccc",
                              transition: "border-color 0.3s ease-in-out",
                            },
                            "&:hover fieldset": {
                              borderColor: "#888",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#fff",
                              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                            },
                          },
                          "& input": {
                            color: "#333",
                            padding: "10px 10px",
                          },
                        }}
                      />

                      <FormControlLabel
                        control={<Checkbox sx={{ color: "#fff", mt: 1, }} />}
                        checked={consent}
                        onChange={(e) => setConsent(e.target.checked)}
                        label={<Typography variant="body2" color="#D3D3D3">Opt in to receive updates about the app and our release.</Typography>}

                      />
                      <Box>
                        <Button
                          variant="contained"
                          className="explore-btn"
                          type="submit"
                          disabled={loading}
                          sx={{
                            background: "transparent",
                            color: "#fff",
                            border: "1px solid #fff",
                            p: "10px 40px",
                            borderRadius: 2,
                            fontWeight: 600,
                            mt: 1,
                          }}
                        >
                          {loading ? <CircularProgress size={24} /> : "Get Early Access"}
                        </Button>
                      </Box>
                    </Box>

                  </Grid>

                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box >
    </>
  );
};

export default SecondPageBanner;
