import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CommonHeader from "./common-header";
import Footer from "../second-page/footer";
import RelatedProducts from "./related-products";
import { truncate } from "lodash";
import { renderStars } from "services/utiles";
import {
  productDetailsSingle,
  productWhyBuy,
} from "redux/store/slice/dashboard/productSlice";

const ProductDetails = () => {
  const { asin } = useParams();
  const dispatch = useDispatch();

  const { productsInfoDetail, loading, whyBuyLoading } = useSelector(
    (state) => state.product
  );
  const [openPopup, setOpenPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (asin) {
      dispatch(productDetailsSingle(asin));
    }
  }, [asin, dispatch]);

  const handleOpenPopup = async (event) => {
    event?.preventDefault();
    setButtonLoading(true);

    try {
      const cachedData = JSON.parse(localStorage.getItem("whyBuyData")) || {};
      let title = productsInfoDetail?.product_title || "";
      title = title.replace(/[^\w\s]/gi, "");


      if (cachedData[title]) {
        setPopupContent(cachedData[title]?.description || "No specific reasons provided.");
      } else {
        const response = await dispatch(productWhyBuy(title)).unwrap();
        cachedData[title] = response;
        localStorage.setItem("whyBuyData", JSON.stringify(cachedData));
        setPopupContent(response?.description || "No specific reasons provided.");
      }
    } catch (error) {
      setPopupContent(error?.message || "Failed to fetch reasons to buy.");
    } finally {
      setButtonLoading(false);
      setOpenPopup(true);
    }
  };


  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  return (
    <Box sx={{ background: "#fff" }} id="top">
      <CommonHeader />
      <Container>
        <Grid
          container
          sx={{
            border: "2px solid rgb(179, 179, 179)",
            borderRadius: 8,
            padding: 4,
            mt: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={7}>
            <Grid container>
              <Grid item xs={12} md={9}>
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={400}
                    sx={{ borderRadius: 4 }}
                  />
                ) : (
                  <Box
                    component="img"
                    src={productsInfoDetail?.product_photo}
                    alt="Product"
                    sx={{
                      height: 400,
                      width: "100%",
                      objectFit: "contain",
                      mixBlendMode: "darken",
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={5}>
            <Box
              sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1.2 }}
            >
              {loading ? (
                <>
                  <Skeleton variant="text" width="80%" height={40} />
                  <Skeleton variant="text" width="100%" height={20} />
                  <Skeleton variant="text" width="60%" height={20} />
                  <Skeleton variant="text" width="40%" height={40} />
                  <Skeleton variant="text" width="60%" height={30} />
                </>
              ) : (
                <>
                  <Typography
                    variant="h5"
                    sx={{ color: "#000", fontWeight: 700, lineHeight: 1.3 }}
                  >
                    {productsInfoDetail?.product_title}
                  </Typography>
                  <Typography variant="body2">
                    {truncate(productsInfoDetail?.product_description, {
                      length: 200,
                    })}
                  </Typography>
                  <Typography variant="body2">
                    {renderStars(
                      parseFloat(productsInfoDetail?.product_star_rating) || 0
                    )}
                    <span className="mx-4">
                      {productsInfoDetail?.product_num_ratings}
                    </span>
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{ color: "#000", fontWeight: 700 }}
                  >
                    $
                    {productsInfoDetail?.product_price}
                  </Typography>
                  <Link
                    href={`${productsInfoDetail?.product_url}?customId=${"gimmieai04-20"}`}
                    className="all-btn"
                    target="_blank"
                  >
                    Buy Now
                  </Link>
                  <Typography
                    variant="body2"
                    sx={{ color: "#375C65", fontWeight: 600 }}
                  >
                    As an Amazon Associate Gimmie earns from qualifying purchases.
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
          <Button
            variant="outlined"
            disabledRipple="true"
            sx={{
              mt: 3,
              borderColor: "#375C65",
              color: "#fff",
              transition: "all 0.3s ease-in-out",
              background: `linear-gradient(
    180deg,
    rgba(90, 155, 172, 1) 10%,
    rgba(55, 92, 102, 1) 100%
  )`,
              "&:hover": {
                backgroundColor: "#375C65",
                color: "#fff",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                transform: "scale(1.1)",
              },
            }}
            onClick={handleOpenPopup}
            disabled={buttonLoading}
          >
            {buttonLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "What makes this is a good gift?"
            )}
          </Button>
        </Grid>
        <Dialog
          open={openPopup}
          onClose={handleClosePopup}
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: 4,
              overflow: 'hidden',
              backgroundColor: '#f9fafc',
            },
          }}
        >
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#375C65',
              color: '#fff',
              fontWeight: 'bold',
              fontSize: 18,
              border: "none",
              padding: '12px 16px',
            }}
          >
            What makes this is a good gift?
            <Button
              onClick={handleClosePopup}
              sx={{
                color: '#fff',
                minWidth: 0,
                padding: 0,
                '&:hover': { color: '#ff6b6b' },
              }}
            >
              ✕
            </Button>
          </DialogTitle>
          <DialogContent
            sx={{
              padding: 3,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            {whyBuyLoading ? (
              <Skeleton
                variant="rectangular"
                width="100%"
                height={100}
                sx={{ borderRadius: 2, backgroundColor: '#e0e0e0' }}
              />
            ) : (
              <Box
                sx={{
                  padding: 2,
                  backgroundColor: '#fff',
                  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                  borderRadius: 2,
                  width: '100%',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    whiteSpace: 'pre-line',
                    color: '#375C65',
                    fontWeight: '500',
                    fontSize: 16,
                  }}
                >
                  {popupContent}
                </Typography>
              </Box>
            )}
          </DialogContent>
        </Dialog>
        <RelatedProducts />
      </Container>
      <Footer />
    </Box>
  );
};

export default ProductDetails;
